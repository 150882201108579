<template>
  <v-row class="d-flex align-center pa-0 ma-0 login-row">
    <v-col cols="12" md="6" class="pa-0 d-none d-sm-none d-md-flex login-left fill-height">
      <div class="wrapper d-flex flex-column" style="z-index:1">
        <v-row>
          <img
            src="@/assets/login/gns-software-logo-white.svg"
            alt="GNS Software Logo"
            width="250px"
          />
        </v-row>
        <v-row class="d-flex align-center">
          <v-col cols="auto">
            <h1>
              Bienvenidos
              <br />a Recibos Digitales
            </h1>
            <h3>Gestioná tus recibos de sueldo en un sólo lugar.</h3>
          </v-col>
        </v-row>
      </div>
      <div class="overlay"></div>
    </v-col>
    <v-col cols="12" md="6" class="pa-0 white login-right">
      <v-row
        class="wrapper d-flex flex-column justify-center px-8 px-sm-10"
        style="max-height:1000px;"
      >
        <div>
          <img
            src="@/assets/login/gns-software-logo.svg"
            alt="GNS Software Logo"
            class="d-flex d-md-none mb-6 logo"
          />
          <h1 class="inicio-sesion">
            Verificación de e-mail
            <div class="left-color-bar"></div>
          </h1>
        </div>

        <p>Tu dirección de correo electrónico ha sido verificada con éxito.</p>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "verify",
  components: {},

  data() {
    return {
      dialoglogin: false,
    };
  },

  computed: {

  },

  created() {
  },

  methods: {

  },
};
</script>

<style scoped>
.wrapper {
  max-width: 550px;
  height: 100%;
  margin: 0 auto;
}

.login-left {
  background-image: url("../../assets/login/login-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 50%;
  color: white;
  position: relative;
}

.login-left h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 97.3%;
  letter-spacing: -0.0243em;
  color: #ffffff;
}

.login-left h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 107.3%;
  letter-spacing: -0.0143em;
  color: #ffffff;
  max-width: 350px;
}

.inicio-sesion {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0143em;
  color: #2d2d2d;
  position: relative;
}

.login-right {
  position: relative;
  background-color: white !important;
  height: 100%;
  max-height: 650px;
}

.login-right .logo {
  width: 250px;
}
.login-right .left-color-bar {
  position: fixed;
  left: 50%;
  width: 14px;
  height: 170px;
  background-color: #ff3301;
}
.overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) -4.63%,
    rgba(196, 196, 196, 0) 103.53%
  );
  opacity: 0.7;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
.login-row{
  background: white; 
  height: 100%;
}

@media (max-width: 960px) {
  .login-right .left-color-bar {
    left: 0;
  }
}

@media (max-width: 700px) {
  .login-right .left-color-bar {
    width: 6px;
    height: 80px;
  }
}

@media (max-height: 850px) and (max-width: 991px) {
  .login-right .logo {
    max-width: 170px;
    max-height: 44px;
  }
  .inicio-sesion {
    font-size: 24px;
    line-height: 0;
  }
  .login-right .v-input input {
    margin: 0;
  }
  .login-right a {
    font-size: 14px;
  }
  .login-right {
    max-height: 500px;
    overflow: hidden !important;
    padding: 0;
  }
  .login-right .v-input {
    padding: 0 !important;
  }
  .login-row{
    display: flex !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
  }

  @media (max-height: 500px) and (max-width: 991px) {
    .login-row{
      display: block !important;
    }
  }
}

</style>
